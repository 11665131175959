import React, {useState} from "react";
import "./style.css";
import {parse} from "query-string";
import {document} from "browser-monads";
import useWebSocket from "react-use-websocket";
import {findByKey} from "./_util";
import {Hydrate} from "./_components/_index";
import useStateRef from "react-usestateref";

const readyStateMap = {
  0: 'CONNECTING',
  1: 'OPEN',
  2: 'CLOSING',
  3: 'CLOSED',
}


function Tassa({wsUri, reconnect = false}) {
  const [page, setPage] = useState()

  const sendMsg = (data) => {
    try {
      sendMessage(JSON.stringify(data))
    } catch (e) {
      console.error("Error sending message: " + e)
    }
  }

  function onOpen() {
    sendMsg({etype: 'INIT'})
  }

  const [$, setReconnect, shouldReconnect] = useStateRef(true);

  function onMessage({data, isTrusted,}) {
    const {etype, data: eventData} = JSON.parse(data)

    if (etype === 'SET') {
      setPage(eventData);
    } else if (etype === 'UPDATE') {
      let dirty = false;
      for (const {key, ...props} of eventData) {
        const node = findByKey(page, key)
        if (node) {
          Object.assign(node, props);
          dirty = true;
        } else {
          console.log("node not found", key, page);
        }
      }
      if (dirty) setPage(page);
    } else if (etype === "END") {
      setReconnect(false)
    }

  }

  const reconnectProps = reconnect ? {
    retryOnError: true,
    shouldReconnect: (e) => shouldReconnect.current,
    reconnectAttempts: 500,
    reconnectInterval: 500,
  } : {}

  const {
    sendMessage, lastMessage, readyState,
  } = useWebSocket(wsUri, {
    onMessage,
    onOpen,
    ...reconnectProps
  });

  return (
    <div className="App">
      <header className="App-header">
        Websocket: {wsUri}, {readyStateMap[readyState]}
      </header>
      <Hydrate sendMsg={sendMsg} {...page}/>
    </div>
  )
}

// h-96 w-96 grid grid-cols-4 grid-rows-4 outline outline-pink-500
// row-span-2 row-span-3 col-span-3 p-1 m-1 outline rounded-lg outline-pink-500
export default function TassaPage() {
  let {
    ws = "",
    reconnect = false,
  } = parse(document.location.search)

  return <Tassa wsUri={ws} reconnect={reconnect}/>
}

